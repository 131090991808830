import React, {Component, Fragment} from 'react';

import ReadyButton from '../../components/ReadyButton';
import AudioPlayer from '../../components/AudioPlayer';

import no_workingMemoryWordsIntro from '../../assets/sounds/no/intros/workingmemory-words-intro.mp3';
import nn_workingMemoryWordsIntro from '../../assets/sounds/nn/intros/workingmemory-words-intro.mp3';
import se_workingMemoryWordsIntro from '../../assets/sounds/se/intros/workingmemory-words-intro.mp3';
import de_workingMemoryWordsIntro from '../../assets/sounds/de/intros/workingmemory-words-intro.wav';
import strings from '../../strings';

class Intro extends Component {
	constructor(props) {
		super(props);
		switch (strings.getLanguage()) {
			case 'nn':
				this.audio = nn_workingMemoryWordsIntro;
				break;
			case 'no':
				this.audio = no_workingMemoryWordsIntro;
				break;

			case 'se':
				this.audio = se_workingMemoryWordsIntro;
				break;
			case 'de':
				this.audio = de_workingMemoryWordsIntro;
				break;
			default:
				this.audio = no_workingMemoryWordsIntro;
				break;
		}
	}

	render() {
		return (
			<Fragment>
					{strings.getLanguage() !=='se' && (
				<p>
					<AudioPlayer autoplay inline src={this.audio} />
					{strings.wsw_intro1} <br />
				</p>
	)}
				{strings.getLanguage() ==='se' && (
					<p>
					<AudioPlayer autoplay inline src={this.audio} />
					{strings.wsw_intro1} <br />
					{strings.wsw_intro2}
				</p>
					
				)}
				<ReadyButton onReadyChange={this.props.setReady} />
			</Fragment>
		);
	}
}

export default Intro;
