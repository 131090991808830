import strings from '../strings';

export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
export const TESTING = 'testing';
const validEnvironments = [DEVELOPMENT, TESTING, PRODUCTION];

const environmentFromEnvVars =
	process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV || 'production';

export const ENVIRONMENT = validEnvironments.includes(environmentFromEnvVars)
	? environmentFromEnvVars
	: PRODUCTION; //Default to production, in case config is missing

if (!validEnvironments.includes(ENVIRONMENT)) {
	console.warn('MISSING ENVIRONMENT SELECTOR');
}
console.log(ENVIRONMENT, process.env.REACT_APP_ENVIRONMENT);

export const IS_IN_PRODUCTION = ENVIRONMENT === 'production';

if (!validEnvironments.includes(ENVIRONMENT)) {
	console.warn('MISSING ENVIRONMENT SELECTOR');
}

export const LANGUAGE = process.env.REACT_APP_LANGUAGE ?? 'no';
strings.setLanguage(LANGUAGE);

const TEST_ENDPOINTS = {
	development: 'http://localhost:3000',
	testing:
		LANGUAGE !== 'de'
			? 'http://lit-testing.eu-central-1.elasticbeanstalk.com'
			: 'https://api.staging.dysmate.de',
	production:
		LANGUAGE !== 'de'
			? 'https://screeningtest.literate.no'
			: 'https://api.dysmate.de',
};
const getFollowupTestUrl = () => {
	const languages = {
		no: {
			development: 'http://localhost:3003',
			testing:
				'http://dysmate-followup.literate.no.s3-website.eu-central-1.amazonaws.com',
			production: 'http://barnetest.literate.no',
		},
		se: {
			development: 'http://localhost:3003',
			testing:
				'http://swedish-followup-staging.s3-website.eu-central-1.amazonaws.com',
			production: 'http://followup.dysmate.se',
		},
		de: {
			development: 'http://localhost:3003',
			testing: 'https://followup.staging.dysmate.de',
			production: 'http://followup.dysmate.de',
		},
	};

	return languages[LANGUAGE][ENVIRONMENT];
};
export const CHILD_TEST_URL = getFollowupTestUrl();
export const SOCKET_ENDPOINT = TEST_ENDPOINTS[ENVIRONMENT];

export const ENDPOINT_URL = TEST_ENDPOINTS[ENVIRONMENT];

export const TYPES = {
	CHILD: 'child',
	ADULT_SCREENING: 'adult-screening',
	ADULT_FOLLOWUP: 'adult-followup',
	YOUTH_SCREENING: 'youth-screening',
	YOUTH_FOLLOWUP: 'youth-followup',
};

export const BANK_ID = 'BankID';
export const BANK_ID_MOBILE = 'BankIDMobile';

export const DASHARRAY_LENGTH = 230;
//To make testing faster you can reduce all test timers to 10 sec with the following:
//The production check is to make sure we never get reduced timers in production
const reduceTimers = false;
export const REDUCED_TIMERS = ENVIRONMENT !== PRODUCTION && reduceTimers;
