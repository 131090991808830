import React, {Component} from 'react';
import strings from '../../strings';

const __DEBUG__ = false;

class Timer extends Component {
	constructor(props) {
		super(props);

		const duration = props.duration || 10;

		this.state = {
			duration: duration,
			timeLeft: duration,
			autoStart: props.autoStart,
			isCounting: false,
			resetOnStop: Boolean(props.resetOnStop),
			allowOvertime: Boolean(props.allowOvertime),
			controls: Boolean(props.controls),
			showTime: Boolean(!props.hidden),
		};
	}

	componentDidMount() {
		this._ismounted = true;
		if (this.state.autoStart) {
			this.start();
		}
	}

	componentWillUnmount() {
		this._ismounted = false;
		this.setState({
			isCounting: false,
		});
	}

	tick = () => {
		const {isCounting, startTime, duration} = this.state;
		this.onTick();

		const now = new Date();

		const timePassed = now.valueOf() - startTime.valueOf();
		const adjustedTimeLeft = duration - timePassed / 1000;
		if (__DEBUG__) console.log('tick', adjustedTimeLeft);

		if (adjustedTimeLeft <= 0) {
			//isComplete
			this.setState({
				timeLeft: 0,
			});
			this.onEnd();
			return;
		}

		this.setState({
			timeLeft: adjustedTimeLeft,
		});

		if (!isCounting || !this._ismounted) return;

		requestAnimationFrame(this.tick);
	};

	start = () => {
		if (__DEBUG__) console.log('start timer');
		console.log('STARTING TIMER');
		this.setState(
			{
				isCounting: true,
				startTime: new Date(),
			},
			() => {
				this.tick();
				this.onStart();
			}
		);
	};

	pause = () => {
		if (__DEBUG__) console.log('pause timer');
		this.setState({
			isCounting: false,
		});

		this.onPause();
	};

	reset = () => {
		if (__DEBUG__) console.log('reset timer');
		this.setState({
			timeLeft: this.state.duration,
		});
		this.onReset();
	};

	stop = () => {
		if (__DEBUG__) console.log('stop timer');
		clearTimeout(this.timer);
		this.onStop();

		this.setState({
			isCounting: false,
		});

		if (this.state.resetOnStop) {
			this.reset();
		}
	};

	onStart = () => {
		if (__DEBUG__) console.log('onStart');
		if (this.props.onStart) this.props.onStart();
	};
	onTick = () => {
		if (__DEBUG__) console.log('onTick');
		if (this.props.onTick) this.props.onTick();
	};
	onPause = () => {
		if (__DEBUG__) console.log('onPause');
		if (this.props.onPause) this.props.onPause();
	};
	onReset = () => {
		if (__DEBUG__) console.log('onReset');
		if (this.props.onReset) this.props.onReset();
	};
	onStop = () => {
		if (__DEBUG__) console.log('onStop');
		if (this.props.onStop) this.props.onStop();
	};
	onEnd = () => {
		if (__DEBUG__) console.log('onEnd');
		if (this.props.onEnd) this.props.onEnd();
	};

	getTimeLeft = () => {
		return Math.floor(this.state.timeLeft);
	};
	getTimeUsed = () => {
		return this.state.duration - this.state.timeLeft;
	};

	getDuration = () => {
		return this.state.duration;
	};

	render() {
		const {controls, showTime} = this.state;

		return (
			<div className="Timer">
				{showTime && (
					<span>
						{strings.remainingTime}&nbsp;
						<b>{this.getTimeLeft()}</b>
					</span>
				)}
				{controls && (
					<div className="Controls">
						<button onClick={this.start}>{strings.start}</button>
						<button onClick={this.pause}>{strings.pause}</button>
						<button onClick={this.stop}>{strings.stop}</button>
						<button onClick={this.reset}>{strings.reset}</button>
					</div>
				)}
			</div>
		);
	}
}

export default Timer;
