import {Howl} from 'howler';

import no_introSound from '../../assets/sounds/no/intros/pd-intro.mp3';
import no_gris from '../../assets/sounds/no/phonemeDeletion/gris.mp3';
import no_ris from '../../assets/sounds/no/phonemeDeletion/gris-g.mp3';
import no_foere from '../../assets/sounds/no/phonemeDeletion/foere.mp3';
import no_oere from '../../assets/sounds/no/phonemeDeletion/foere-f.mp3';
import no_sel from '../../assets/sounds/no/phonemeDeletion/sel.mp3';
import no_se from '../../assets/sounds/no/phonemeDeletion/sel-l.mp3';
import no_gaas from '../../assets/sounds/no/phonemeDeletion/gaas-g.mp3';
import no_maal from '../../assets/sounds/no/phonemeDeletion/maal-m.mp3';
import no_broed from '../../assets/sounds/no/phonemeDeletion/broed-b.mp3';
import no_klaer from '../../assets/sounds/no/phonemeDeletion/klaer-k.mp3';
import no_brev from '../../assets/sounds/no/phonemeDeletion/brev-b.mp3';
import no_trygg from '../../assets/sounds/no/phonemeDeletion/trygg-t.mp3';
import no_drap from '../../assets/sounds/no/phonemeDeletion/drap-d.mp3';
import no_skyll from '../../assets/sounds/no/phonemeDeletion/skyll-l.mp3';
import no_laas from '../../assets/sounds/no/phonemeDeletion/laas-s.mp3';
import no_bro from '../../assets/sounds/no/phonemeDeletion/bro-r.mp3';
import no_klatre from '../../assets/sounds/no/phonemeDeletion/klatre-t.mp3';
import no_malt from '../../assets/sounds/no/phonemeDeletion/malt-l.mp3';
import no_bry from '../../assets/sounds/no/phonemeDeletion/bry-r.mp3';
import no_lekse from '../../assets/sounds/no/phonemeDeletion/lekse-k.mp3';
import no_sport from '../../assets/sounds/no/phonemeDeletion/sport-p.mp3';
import no_loff from '../../assets/sounds/no/phonemeDeletion/loff-f.mp3';

import nn_introSound from '../../assets/sounds/nn/intros/pd-intro.mp3';
import nn_gris from '../../assets/sounds/nn/phonemeDeletion/gris.mp3';
import nn_ris from '../../assets/sounds/nn/phonemeDeletion/gris-g.mp3';
import nn_foere from '../../assets/sounds/nn/phonemeDeletion/foere.mp3';
import nn_oere from '../../assets/sounds/nn/phonemeDeletion/foere-f.mp3';
import nn_sel from '../../assets/sounds/nn/phonemeDeletion/sel.mp3';
import nn_se from '../../assets/sounds/nn/phonemeDeletion/sel-l.mp3';
import nn_gaas from '../../assets/sounds/nn/phonemeDeletion/gaas-g.mp3';
import nn_maal from '../../assets/sounds/nn/phonemeDeletion/maal-m.mp3';
import nn_broed from '../../assets/sounds/nn/phonemeDeletion/broed-b.mp3';
import nn_klede from '../../assets/sounds/nn/phonemeDeletion/klede-k.mp3';
import nn_brev from '../../assets/sounds/nn/phonemeDeletion/brev-b.mp3';
import nn_trygg from '../../assets/sounds/nn/phonemeDeletion/trygg-t.mp3';
import nn_drap from '../../assets/sounds/nn/phonemeDeletion/drap-d.mp3';
import nn_skyl from '../../assets/sounds/nn/phonemeDeletion/skyl-l.mp3';
import nn_laas from '../../assets/sounds/nn/phonemeDeletion/laas-s.mp3';
import nn_bro from '../../assets/sounds/nn/phonemeDeletion/bru-r.mp3';
import nn_klatre from '../../assets/sounds/nn/phonemeDeletion/klatre-t.mp3';
import nn_malt from '../../assets/sounds/nn/phonemeDeletion/malt-l.mp3';
import nn_bry from '../../assets/sounds/nn/phonemeDeletion/bry-r.mp3';
import nn_lekse from '../../assets/sounds/nn/phonemeDeletion/lekse-k.mp3';
import nn_sport from '../../assets/sounds/nn/phonemeDeletion/sport-p.mp3';
import nn_loff from '../../assets/sounds/nn/phonemeDeletion/loff-f.mp3';

import se_introSound from '../../assets/sounds/se/intros/SWFUC2-01.mp3';
import se_gris from '../../assets/sounds/se/phonemeDeletion/SWFUC2-02.mp3';
import se_ris from '../../assets/sounds/se/phonemeDeletion/SWFUC2-03.mp3';
import se_foere from '../../assets/sounds/se/phonemeDeletion/SWFUC2-04.mp3';
import se_oere from '../../assets/sounds/se/phonemeDeletion/SWFUC2-05.mp3';
import se_len from '../../assets/sounds/se/phonemeDeletion/SWFUC2-06.mp3';
import se_le from '../../assets/sounds/se/phonemeDeletion/SWFUC2-07.mp3';
import se_sang from '../../assets/sounds/se/phonemeDeletion/SWFUC2-09.mp3';
import se_maal from '../../assets/sounds/se/phonemeDeletion/SWFUC2-10.mp3';
import se_broed from '../../assets/sounds/se/phonemeDeletion/SWFUC2-11.mp3';
import se_klokka from '../../assets/sounds/se/phonemeDeletion/SWFUC2-12.mp3';
import se_skaagg from '../../assets/sounds/se/phonemeDeletion/SWFUC2-13.mp3';
import se_trygg from '../../assets/sounds/se/phonemeDeletion/SWFUC2-14.mp3';
import se_plog from '../../assets/sounds/se/phonemeDeletion/SWFUC2-15.mp3';
import se_taag from '../../assets/sounds/se/phonemeDeletion/SWFUC2-16.mp3';
import se_palm from '../../assets/sounds/se/phonemeDeletion/SWFUC2-17.mp3';
import se_bro from '../../assets/sounds/se/phonemeDeletion/SWFUC2-18.mp3';
import se_lampa from '../../assets/sounds/se/phonemeDeletion/SWFUC2-19.mp3';
import se_spaar from '../../assets/sounds/se/phonemeDeletion/SWFUC2-20.mp3';
import se_loft from '../../assets/sounds/se/phonemeDeletion/SWFUC2-21.mp3';
import se_laxa from '../../assets/sounds/se/phonemeDeletion/SWFUC2-22.mp3';
import se_flicka from '../../assets/sounds/se/phonemeDeletion/SWFUC2-23.mp3';
import se_lok from '../../assets/sounds/se/phonemeDeletion/SWFUC2-24.mp3';

export const no_intro = {
	intro: no_introSound,
	task1: no_gris,
	task1a: no_ris,
	task2: no_foere,
	task2a: no_oere,
	task3: no_sel,
	task3a: no_se,
};

export const nn_intro = {
	intro: nn_introSound,
	task1: nn_gris,
	task1a: nn_ris,
	task2: nn_foere,
	task2a: nn_oere,
	task3: nn_sel,
	task3a: nn_se,
};

export const se_intro = {
	intro: se_introSound,
	task1: se_gris,
	task1a: se_ris,
	task2: se_foere,
	task2a: se_oere,
	task3: se_len,
	task3a: se_le,
};

export const no_test = [
	new Howl({src: no_gaas}),
	new Howl({src: no_maal}),
	new Howl({src: no_broed}),
	new Howl({src: no_klaer}),
	new Howl({src: no_brev}),
	new Howl({src: no_trygg}),
	new Howl({src: no_drap}),
	new Howl({src: no_skyll}),
	new Howl({src: no_laas}),
	new Howl({src: no_bro}),
	new Howl({src: no_klatre}),
	new Howl({src: no_malt}),
	new Howl({src: no_bry}),
	new Howl({src: no_lekse}),
	new Howl({src: no_sport}),
	new Howl({src: no_loff}),
];

export const nn_test = [
	new Howl({src: nn_gaas}),
	new Howl({src: nn_maal}),
	new Howl({src: nn_broed}),
	new Howl({src: nn_klede}),
	new Howl({src: nn_brev}),
	new Howl({src: nn_trygg}),
	new Howl({src: nn_drap}),
	new Howl({src: nn_skyl}),
	new Howl({src: nn_laas}),
	new Howl({src: nn_bro}),
	new Howl({src: nn_klatre}),
	new Howl({src: nn_malt}),
	new Howl({src: nn_bry}),
	new Howl({src: nn_lekse}),
	new Howl({src: nn_sport}),
	new Howl({src: nn_loff}),
];

export const se_test = [
	new Howl({src: se_sang}),
	new Howl({src: se_maal}),
	new Howl({src: se_broed}),
	new Howl({src: se_klokka}),
	new Howl({src: se_skaagg}),
	new Howl({src: se_trygg}),
	new Howl({src: se_plog}),
	new Howl({src: se_taag}),
	new Howl({src: se_palm}),
	new Howl({src: se_bro}),
	new Howl({src: se_lampa}),
	new Howl({src: se_spaar}),
	new Howl({src: se_loft}),
	new Howl({src: se_laxa}),
	new Howl({src: se_flicka}),
	new Howl({src: se_lok}),
];
